<template>
	<section class="login-container">
		<div class="form-container">
			<img class="logo" src="/img/logo-without-text.svg" alt="">
			<h1>Se connecter</h1>
			<form @submit.prevent="login()">
				<div class="input-container">
					<label for="">Nom d'utilisateur
						<input type="text" v-model="form.username" placeholder="username">
					</label>
				</div>
				<div class="input-container">
					<label for="">Code
						<input :type="isPasswordVisible ? 'text' : 'password'" v-model="form.code" placeholder="code">
						<img @click="isPasswordVisible = ! isPasswordVisible" :src="isPasswordVisible ? '/img/student-place/show.svg' : '/img/student-place/hide.svg'" alt="">
					</label>
				</div>
				<button class="submit dark">Se connecter</button>
			</form>
		</div>
	</section>
</template>

<script>
import { decrypt } from '../../utils'
import axios from 'axios'
import Config from '../../config'
export default {
	data() {
		return {
			form:{
				username:'',
				code: '',
				class_id: ''
			},
			isPasswordVisible: false
		}
	},

	methods: {
		async login(){
			this.form.class_id = decrypt(this.$route.params.classId)
			await axios.post(`${Config.backend_url}/login-student/`, this.form)
				.then((res)=>{
					this.$store.state.student = {
						username: res.data.student.username,
						class_id: res.data.student.class_id,
						id: res.data.student.id,
						token: res.data.access
					}
					localStorage.setItem('studentToken', this.$store.state.student.token)
					this.$store.state.toast = {
						status: 'success',
						text: "Connexion réussie",
					}
					this.$router.push({name: 'StudentPlaceHome', params: {classId: this.$route.params.classId}})
				})
				.catch(err => {
					console.error(err)
					this.$store.state.toast = {
					status: 'error',
					text: "Nom d'utilisateur ou code incorrect",
				}
			})

					
		}
	},
	
}
</script>

<style scoped lang="scss"> 
@import '../../assets/scss/student-place/login.scss';
</style>
